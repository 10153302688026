.m_17884d0f {
  --carousel-height: auto;
  position: relative;
}

.m_a2dae653 {
  height: var(--carousel-height);
  overflow: hidden;
}

.m_fcd81474 {
  display: flex;
  flex-direction: row;
  height: var(--carousel-height);
}

:where([data-include-gap-in-size]) .m_fcd81474:where([data-orientation='vertical']) {
      margin-bottom: calc(var(--carousel-slide-gap) * -1);
    }

:where([data-include-gap-in-size]) .m_fcd81474:where([data-orientation='horizontal']) {
      margin-inline-end: calc(var(--carousel-slide-gap) * -1);
    }

.m_fcd81474:where([data-orientation='vertical']) {
    flex-direction: column;
  }

.m_39bc3463 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;
}

.m_39bc3463:where([data-orientation='vertical']) {
    inset-inline-start: calc(50% - var(--carousel-control-size) / 2);
    top: 0;
    bottom: 0;
    flex-direction: column;
    padding: var(--carousel-controls-offset) 0;
  }

.m_39bc3463:where([data-orientation='horizontal']) {
    inset-inline-start: 0;
    inset-inline-end: 0;
    top: calc(50% - var(--carousel-control-size) / 2);
    flex-direction: row;
    padding: 0 var(--carousel-controls-offset);
  }

.m_64f58e10 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--carousel-control-size);
  min-height: var(--carousel-control-size);
  border-radius: var(--carousel-control-size);
  pointer-events: all;
  background-color: var(--mantine-color-white);
  color: var(--mantine-color-black);
  box-shadow: var(--mantine-shadow-md);
  border: 1px solid var(--mantine-color-gray-3);
  transition: opacity 100ms ease;
}

:where([data-mantine-color-scheme='light']) .m_64f58e10 {
    opacity: 0.85;
}

:where([data-mantine-color-scheme='dark']) .m_64f58e10 {
    opacity: 0.65;
}

@media (hover: hover) {

  .m_64f58e10:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m_64f58e10:active {
    opacity: 1;
  }
}

.m_71ea3ab1 {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: calc(0.5rem * var(--mantine-scale));
  pointer-events: none;
}

.m_71ea3ab1:where([data-orientation='vertical']) {
    bottom: 0;
    top: 0;
    inset-inline-end: var(--mantine-spacing-md);
    flex-direction: column;
  }

.m_71ea3ab1:where([data-orientation='horizontal']) {
    bottom: var(--mantine-spacing-md);
    inset-inline-start: 0;
    inset-inline-end: 0;
    flex-direction: row;
  }

.m_eae68602 {
  pointer-events: all;
  border-radius: var(--mantine-radius-xl);
  box-shadow: var(--mantine-shadow-sm);
  opacity: 0.6;
  background-color: var(--mantine-color-white);
}

@media (hover: hover) {

  .m_eae68602:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m_eae68602:active {
    opacity: 1;
  }
}

.m_eae68602:where([data-active]) {
    opacity: 1;
  }

.m_eae68602:where([data-orientation='vertical']) {
    width: calc(0.3125rem * var(--mantine-scale));
    height: calc(1.5625rem * var(--mantine-scale));
  }

.m_eae68602:where([data-orientation='horizontal']) {
    width: calc(1.5625rem * var(--mantine-scale));
    height: calc(0.3125rem * var(--mantine-scale));
  }

.m_d98df724 {
  position: relative;
  flex: 0 0 var(--carousel-slide-size, 100%);
}

.m_17884d0f:where([data-include-gap-in-size]) .m_d98df724:where([data-orientation='vertical']) {
      padding-bottom: var(--carousel-slide-gap);
    }

.m_17884d0f:where([data-include-gap-in-size]) .m_d98df724:where([data-orientation='horizontal']) {
      padding-inline-end: var(--carousel-slide-gap);
    }

.m_17884d0f:where(:not([data-include-gap-in-size])) .m_d98df724:where([data-orientation='vertical']) {
      margin-bottom: var(--carousel-slide-gap);
    }

.m_17884d0f:where(:not([data-include-gap-in-size])) .m_d98df724:where([data-orientation='horizontal']) {
      margin-inline-end: var(--carousel-slide-gap);
    }

@font-face {
font-family: '__sohneLeicht_2a3dbe';
src: url(/_next/static/media/7682b280ce4095ac-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__sohneLeicht_Fallback_2a3dbe';src: local("Arial");ascent-override: 103.68%;descent-override: 28.89%;line-gap-override: 0.00%;size-adjust: 100.02%
}.__className_2a3dbe {font-family: '__sohneLeicht_2a3dbe', '__sohneLeicht_Fallback_2a3dbe', Futura, Helvetica, sans-serif, Tahoma, Verdana, sans-serif
}.__variable_2a3dbe {--sohne-leicht: '__sohneLeicht_2a3dbe', '__sohneLeicht_Fallback_2a3dbe', Futura, Helvetica, sans-serif, Tahoma, Verdana, sans-serif
}

@font-face {
font-family: '__sohneFett_a0d959';
src: url(/_next/static/media/7584226bda665cc7-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__sohneFett_Fallback_a0d959';src: local("Arial");ascent-override: 97.17%;descent-override: 27.08%;line-gap-override: 0.00%;size-adjust: 106.72%
}.__className_a0d959 {font-family: '__sohneFett_a0d959', '__sohneFett_Fallback_a0d959', Futura, Helvetica, sans-serif, Tahoma, Verdana, sans-serif
}.__variable_a0d959 {--sohne-fett: '__sohneFett_a0d959', '__sohneFett_Fallback_a0d959', Futura, Helvetica, sans-serif, Tahoma, Verdana, sans-serif
}

